import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  accentColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";

export interface IStyle {
  block: any;
  blockRight: any;
  blockLeft: any;
  dayNames: any;
  calendarBlock: any;
  calendar: any;
  dateInfo: any;
  dateInfoAlert: any;
  footer: any;
  button: any;
  arrow: any;
  previousArrow: any;
  nextArrow: any;
  disabledArrow: any;
  entering: any;
  entered: any;
  exiting: any;
}

export const enterDuration = 360;
export const exitDelay = 100;
export const exitDuration: number = enterDuration + exitDelay;

export const HorizontalDatePickerStyle: IStyle = StyleSheet.create({
  block: {
    position: "absolute",
    height: "auto",
    boxSizing: "border-box",
    width: "auto",
    minWidth: "36rem",
    backgroundColor: "white",
    borderRadius: borderRadius.normal,
    margin: `0 ${space.atom}`,
    boxShadow: "0 6px 30px rgba(40,40,40,0.1)",
    zIndex: 1,
  },
  blockRight: {
    right: 0,
  },
  blockLeft: {
    left: 0,
  },
  dayNames: {
    paddingTop: "0.5rem",
    marginBottom: space.atom2x,
    height: "2rem",
    borderBottom: `1px solid ${borderColor.primaryLightColor}`,
    boxSizing: "border-box",
  },
  calendarBlock: {
    position: "relative",
    display: "flex",
    padding: `0 ${space.atom1_5x} ${space.atom}`,
  },
  calendar: {
    width: "50%",
    padding: `${space.atom} ${space.atom1_5x}`,
  },
  dateInfo: {
    textAlign: "center",
    fontSize: fontSize.caption,
    padding: `${space.atom0_5x} ${space.atom}`,
    lineHeight: lineHeight.caption3,
    letterSpacing: "0.1em",
  },
  dateInfoAlert: {
    textAlign: "center",
    fontSize: fontSize.caption,
    padding: `${space.atom0_5x} ${space.atom}`,
    lineHeight: lineHeight.caption3,
    color: accentColor.alertColor,
  },
  footer: {
    padding: space.atom,
    boxSizing: "border-box",
    borderTop: `1px solid ${borderColor.primaryLightColor}`,
  },
  button: {
    ":disabled": {
      background: textColor.disabledColor,
    },
  },
  arrow: {
    position: "absolute",
    display: "block",
    cursor: "pointer",
    fontSize: "0.75rem",
    top: 0,
    width: "30px",
    textAlign: "center",
    color: accentColor.primaryColor,
    userSelect: "none",
    ":hover": {
      color: `transparentize(${accentColor.primaryColor}, 0.6)`,
    },
  },
  previousArrow: {
    left: 0,
    padding: "1.375rem 1.5rem 1.375rem 0.4rem",
  },
  nextArrow: {
    right: 0,
    left: "auto",
    padding: "1.375rem 0.4rem 1.375rem 1.5rem",
  },
  disabledArrow: {
    color: textColor.disabledColor,
    ":hover": {
      color: textColor.disabledColor,
    },
  },
  entering: {
    opacity: 0,
    transform: "translateY(-16px)",
  },
  entered: {
    opacity: 1,
    transform: "translateY(0)",
  },
  exiting: {
    transition: `all ${enterDuration}ms ease 100ms`,
  },
});

export const defaultStyle: any = {
  transition: `all ${enterDuration}ms ease`,
  opacity: 0,
  transform: "translateY(-16px)",
  touchAction: "manipulation",
};

export const transitionStyles: any = {
  entering: {
    opacity: 0,
    transform: "translateY(-16px)",
  },
  entered: {
    opacity: 1,
    transform: "translateY(0)",
  },
  exiting: {
    transition: `all ${enterDuration}ms ease ${exitDelay}ms`,
  },
  exited: {
    display: "none",
  },
};
