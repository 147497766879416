import * as React from "react";
import { css } from "aphrodite";
import { withTranslation, WithTranslation } from "react-i18next";
import { $, on, off } from "dom7";
import {
  addMonths,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  format,
  subMonths,
  isBefore,
  addDays,
  subDays,
} from "date-fns";
import { HorizontalDatePickerStyle } from "./HorizontalDatePickerStyle";
import Transition from "react-transition-group/Transition";
import {
  defaultStyle,
  enterDuration,
  exitDuration,
  transitionStyles,
} from "../Calendar/CalendarStyle";
import { Icon } from "../../atoms/Icon";
import { Calendar } from "./Calendar";
import { isMobile } from "main/javascripts/utils/DeviceUtil";
import { DateTypes } from "main/javascripts/constants/DateTypes";

$.fn.on = on;
$.fn.off = off;

const createMonthNum = 2;

interface IState {
  date: Date;
  startDate: Date | null;
  endDate: Date | null;
  temporaryDate: Date | null;
  isOutOfRange: boolean;
  isDisplayed: boolean;
}

interface IProps {
  fromDate: Date | null;
  toDate: Date | null;
  displayedPicker: boolean;
  onUpdate(
    startDate: Date | null,
    endDate: Date | null,
    isEndStep: boolean
  ): void;
  hidePicker(): void;
  disableDate?: any;
  numberOfMonths: number;
  isSingleSelection?: boolean;
  canSelectSameDate?: boolean;
  dateType?: string;
  maxDays?: number;
  outOfRangeMessage?: string;
  startDateLabel?: string;
  endDateLabel?: string;
  fromInputRef?: any;
  toInputRef?: any;
  alignmentStyleKey?: keyof IAlignmentStyle;
  t?: any;
}

class HorizontalDatePickerComponent extends React.Component<
  IProps & WithTranslation,
  IState
> {
  public ref: any;
  public calendarStyle: any;

  public constructor(props: IProps & WithTranslation) {
    super(props);
    this.ref = React.createRef();
    const today: Date = new Date();
    const date: Date = props.fromDate || this.getDate(today, props.disableDate);
    this.state = {
      date: date,
      startDate: props.fromDate,
      endDate: props.toDate,
      temporaryDate: props.toDate,
      isOutOfRange: false,
      isDisplayed: false,
    };
    this.calendarStyle = {
      calendarStyle: HorizontalDatePickerStyle.calendar,
    };
  }

  public clickOutsideHandler: (e: any) => void = (e: any) => {
    const { fromInputRef, toInputRef } = this.props;
    if (
      this.state.isDisplayed &&
      this.ref &&
      this.ref.current &&
      !this.ref.current.contains(e.target)
    ) {
      if (
        (fromInputRef && fromInputRef.contains(e.target)) ||
        (toInputRef && toInputRef.contains(e.target))
      ) {
        return;
      }
      if (this.checkIsMounted()) {
        this.resetState();
        this.props.hidePicker();
      }
    }
  };

  public componentDidMount(): void {
    const evt: string = isMobile() ? "touchend" : "click";
    $(document).on(evt, this.clickOutsideHandler);
  }

  public componentWillUnmount(): void {
    const evt: string = isMobile() ? "touchend" : "click";
    $(document).off(evt, this.clickOutsideHandler);
  }

  public componentDidUpdate(prevProps: any): void {
    const { disableDate } = this.props;
    const { date } = this.state;
    if (
      prevProps.displayedPicker !== this.props.displayedPicker &&
      this.props.displayedPicker
    ) {
      // EDGEで日付ボックスクリック時にテキストが選択状態になってしまい
      // 最初のクリックでカレンダーの日付を選択できない問題の対策
      const selection: any = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
    }
    if (prevProps.disableDate !== disableDate && disableDate) {
      this.setState({
        date: this.getDate(date, disableDate),
      });
    }
  }

  private isEndSelection(): boolean {
    return this.props.dateType === DateTypes.toDate;
  }

  private checkIsMounted(): boolean {
    return this.ref.current !== null;
  }

  public getDate(date: Date, disableDate: Date | null): Date {
    return disableDate && isBefore(date, disableDate) ? disableDate : date;
  }

  public resetDate: () => void = () => {
    this.setState({ startDate: null, endDate: null, temporaryDate: null });
  };

  public resetState: () => void = () => {
    this.setState({ temporaryDate: null });
  };

  private isInvalidEndDate(startDate: Date, endDate: Date): boolean {
    if (this.props.canSelectSameDate) {
      return differenceInCalendarDays(endDate, startDate) < 0;
    }
    return differenceInCalendarDays(endDate, startDate) <= 0;
  }

  private onSelect: (date: Date) => void = (date: Date) => {
    const { isSingleSelection, toDate } = this.props;
    const { startDate, endDate } = this.state;
    if (
      isSingleSelection ||
      !this.isEndSelection() ||
      !startDate ||
      this.isInvalidEndDate(startDate, date)
    ) {
      let newEndDate: Date | null = endDate;
      if (
        toDate &&
        (differenceInCalendarDays(date, toDate) > 0 ||
          this.isOverMaxDays(date, toDate))
      ) {
        newEndDate = addDays(date, 1);
      }
      this.setState({
        startDate: date,
        endDate: newEndDate,
        temporaryDate: null,
        isOutOfRange: false,
      });
      this.props.onUpdate(date, newEndDate, false);
    } else {
      let newStartDate: Date | null = startDate;
      if (!newStartDate) {
        newStartDate = subDays(date, 1);
      }
      this.setState({
        startDate: newStartDate,
        endDate: date,
        temporaryDate: null,
      });
      if (this.isOverMaxDays(newStartDate, date)) {
        this.setState({ isOutOfRange: true });
      } else {
        this.props.onUpdate(newStartDate, date, true);
        this.props.hidePicker();
        this.setState({ isOutOfRange: false });
      }
    }
  };
  private onMouseOver: (date: Date) => void = (date: Date) => {
    const { maxDays, isSingleSelection } = this.props;
    const { startDate, endDate } = this.state;
    if (isSingleSelection) {
      return;
    }
    let isOutOfRange = false;
    if (
      startDate &&
      this.isEndSelection() &&
      differenceInCalendarDays(date, startDate) > 0
    ) {
      if (maxDays && differenceInCalendarDays(date, startDate) > maxDays) {
        isOutOfRange = true;
      }
      this.setState({ temporaryDate: date, isOutOfRange: isOutOfRange });
    } else if (
      endDate &&
      !this.isEndSelection() &&
      differenceInCalendarDays(endDate, date) > 0
    ) {
      this.setState({ temporaryDate: date, isOutOfRange: isOutOfRange });
    }
  };
  private onMouseOut: () => void = () => {
    const { startDate, endDate } = this.state;
    this.setState({
      temporaryDate: null,
      isOutOfRange: this.isOverMaxDays(startDate, endDate),
    });
  };
  private isOverMaxDays(startDate: Date | null, endDate: Date | null): boolean {
    const { maxDays } = this.props;
    if (!startDate || !endDate) {
      return false;
    }
    return !!maxDays && differenceInCalendarDays(endDate, startDate) > maxDays;
  }
  private renderDateInfo: () => any = () => {
    const {
      isSingleSelection,
      outOfRangeMessage,
      startDateLabel,
      endDateLabel,
      t,
    } = this.props;
    const { startDate, endDate, isOutOfRange } = this.state;
    let infoText = "";
    const dateFormat = "yyyy/LL/dd";
    const startDateText: string = startDate
      ? format(startDate, dateFormat)
      : "";
    const endDateText: string = endDate ? format(endDate, dateFormat) : "";
    let infoClass: any = HorizontalDatePickerStyle.dateInfo;

    if (isSingleSelection) {
      if (!startDate) {
        infoText = t("search.selectDate", {
          dateName: startDateLabel || t("label:common.departureDate"),
        });
      } else {
        infoText = startDateText;
      }
    } else {
      if (outOfRangeMessage && isOutOfRange) {
        infoText = outOfRangeMessage;
        infoClass = HorizontalDatePickerStyle.dateInfoAlert;
      } else if (!startDate) {
        infoText = t("search.selectDate", {
          dateName: startDateLabel || t("label:common.departureDate"),
        });
      } else {
        if (!endDate) {
          infoText = t("search.selectDate", {
            dateName: endDateLabel || t("label:common.returnDate"),
          });
        } else {
          infoText = `${startDateText} - ${endDateText}`;
        }
      }
    }
    return <div className={css(infoClass)}>{infoText}</div>;
  };

  private changePrevMonth: () => void = () => {
    if (!this.canChangePrevMonth()) {
      return;
    }
    this.setState({
      date: subMonths(this.state.date, 1),
    });
  };
  private changeNextMonth: () => void = () => {
    if (!this.canChangeNextMonth()) {
      return;
    }
    this.setState({
      date: addMonths(this.state.date, 1),
    });
  };
  private canChangePrevMonth(): boolean {
    const today: Date = new Date();
    const previousMonth: Date = subMonths(this.state.date, 1);
    return differenceInCalendarMonths(today, previousMonth) <= 0;
  }
  private canChangeNextMonth(): boolean {
    const today: Date = new Date();
    const nextMonth: Date = addMonths(this.state.date, 1);
    return differenceInCalendarMonths(nextMonth, today) <= 12;
  }

  private onEntered: () => void = () => {
    this.setState({ isDisplayed: true });
  };

  private onExit: () => void = () => {
    this.setState({ isDisplayed: false });
  };

  public render(): JSX.Element | null {
    const { alignmentStyleKey, disableDate, displayedPicker } = this.props;
    const date: Date = this.state.date;
    const disabledPreviousStyles: any = this.canChangePrevMonth()
      ? null
      : HorizontalDatePickerStyle.disabledArrow;
    const disabledNextStyles: any = this.canChangeNextMonth()
      ? null
      : HorizontalDatePickerStyle.disabledArrow;
    const calendarDisableDate: Date = disableDate;
    let startDate: Date | null = this.state.startDate;
    let endDate: Date | null = this.state.endDate;
    if (!this.isEndSelection()) {
      startDate = this.state.temporaryDate || startDate;
    } else {
      endDate = this.state.temporaryDate || endDate;
    }
    return (
      <Transition
        timeout={{
          enter: enterDuration,
          exit: exitDuration,
        }}
        in={displayedPicker}
        unmountOnExit={true}
        onEntered={this.onEntered}
        onExit={this.onExit}
        nodeRef={this.ref}
      >
        {(state: any): JSX.Element => (
          <div
            className={`${css(
              HorizontalDatePickerStyle.block,
              alignmentStyleKey === "left"
                ? HorizontalDatePickerStyle.blockLeft
                : HorizontalDatePickerStyle.blockRight
            )} datePicker`}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            ref={this.ref}
          >
            <div className={css(HorizontalDatePickerStyle.calendarBlock)}>
              {
                // tslint:disable-next-line:prefer-array-literal
                Array.from(Array(createMonthNum).keys()).map(
                  (index: number) => {
                    const calendarDate: Date = addMonths(date, index);
                    return (
                      <Calendar
                        key={index}
                        date={calendarDate}
                        startDate={startDate}
                        endDate={endDate}
                        onSelect={this.onSelect}
                        onMouseOver={this.onMouseOver}
                        onMouseOut={this.onMouseOut}
                        disableDate={calendarDisableDate}
                        showDayNames={true}
                        styles={this.calendarStyle}
                      />
                    );
                  }
                )
              }
              <a
                className={css(
                  HorizontalDatePickerStyle.arrow,
                  HorizontalDatePickerStyle.previousArrow,
                  disabledPreviousStyles
                )}
                onClick={this.changePrevMonth}
              >
                <Icon styleKey="arrowLeft" />
              </a>
              <a
                className={css(
                  HorizontalDatePickerStyle.arrow,
                  HorizontalDatePickerStyle.nextArrow,
                  disabledNextStyles
                )}
                onClick={this.changeNextMonth}
              >
                <Icon styleKey="arrowRight" />
              </a>
            </div>
            <div className={css(HorizontalDatePickerStyle.footer)}>
              {this.renderDateInfo()}
            </div>
          </div>
        )}
      </Transition>
    );
  }
}
export const HorizontalDatePicker: any = withTranslation(["component"])(
  HorizontalDatePickerComponent
);

export interface IAlignmentStyle {
  left: any;
  right: any;
}
